import { Component, OnInit } from '@angular/core';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { PRODUCTS } from 'src/app/models/products';
import { Router } from '@angular/router';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  products = PRODUCTS;
  faHeart = faHeart;
  activeProduct;
  isImgAvailableOnMobile: boolean;
  benefits; 
  benefitsBesideDesktop = [];
  benefitsBesideMobile = [];

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.triggerAnimations();

    if(this.router.url.length > 1) {
      this.activeProduct = this.products.filter((product) => product.state == this.router.url.replace('/', ''));
    } 

    this.isImgAvailableOnMobile = this.activeProduct[0].imgAvailableOnMobile;
    this.benefits = this.activeProduct[0].benefits;

    if(this.isImgAvailableOnMobile) {
 
      let index = this.benefits.length / 2; 
      index = Math.ceil(index);

      for(var i = 0; i < index; i++ ) {
        this.benefitsBesideDesktop.push(this.benefits[i]);
      }

      for(var i = index; i < this.benefits.length; i++) {
        this.benefitsBesideMobile.push(this.benefits[i])
      }
      
    } else {
      this.benefitsBesideDesktop = this.benefits;
    }
  }

  triggerAnimations() {
    window.scrollTo(0, 1);
    window.scrollTo(0, 0);
  }

}
