import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-desktop-navigation',
  templateUrl: './desktop-navigation.component.html',
  styleUrls: ['./desktop-navigation.component.scss']
})
export class DesktopNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
