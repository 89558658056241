import { Component, OnInit } from '@angular/core';
import { faUsers, faEye, faSmile, faHome } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-strong-numbers',
  templateUrl: './strong-numbers.component.html',
  styleUrls: ['./strong-numbers.component.scss']
})
export class StrongNumbersComponent implements OnInit {

  faUsers = faUsers;
  faEye = faEye;
  faSmile = faSmile;
  faHome = faHome;

  constructor() { }

  ngOnInit(): void {
  }

}
