<div class="Team">
  <div class="Team__Panel">
    <div class="container">
      <h2 class="Team__Heading">Our Team</h2>
      <div class="Team__Heading--border"></div>
      <div class="swiper-container" [swiper]="config">
        <div class="swiper-wrapper mb-md-5">
          <div *ngFor="let t of team" class="swiper-slide">
            <img class="Team__TeamImg" src="{{ t.img }}" />
            <div class="Team__Info mt-3">
              <h5>{{ t.name }}</h5>
              <p c>{{ t.jobTitle }}</p>
            </div>
          </div>
        </div>

        <div class="swiper-scrollbar"></div>
      </div>
    </div>
  </div>
</div>
