export const PRODUCTS = [{
        title: 'MYVALU',
        tagline: 'Get up to date Valuations on the move',
        state: 'valuations-tool',
        tab: '/marketinganddatasolutions',
        description: 'MyHome.ie have developed a map based search tool to give a comprehensive, local market view for real-time Valuations with an instant branded output for your customers.',
        benefits: [
            {id: 1, text: 'Map based search for instant local insight'},
            {id: 2, text: 'Branded brochure to deliver to your vendor'},
            {id: 3, text: 'Instant access to MyHome and PPR properties nationwide'}
        ],
        imgAvailableOnMobile: false
    },{
        title: 'Market Research',
        tagline: 'Our comprehensive market research will help you win more appraisals and prospect more efficiently',
        state: 'market-research',
        tab: '/marketinganddatasolutions',
        description: 'MyHome.ie have taken all of our Residential Property listings and matched them with the Property Price Register to provide you with the most comprehensive overview of the Irish property Market.',
        benefits: [
            {id: 1, text: 'Win more vendor instructions '},
            {id: 2, text: 'Instant access to hundreds of thousands of listings'},
            {id: 3, text: 'Complete valuations in a more efficient & robust manner'}
        ],
        imgAvailableOnMobile: false
    },
    {
        title: 'MyHome Live',
        tagline: 'Ability to  offer digital open viewings without having to allow too many people through your property',
        state: 'myhome-live',
        tab: '/marketinganddatasolutions',
        description: 'MyHome Live enables estate agents to show a property live to any number of potential buyers from the comfort of their homes. The agent can answer questions in real-time allowing potential buyers to gain a sense of the property almost as much as if they were there themselves.',
        benefits: [
            {id: 1, text: 'Enables you to conduct open property viewings in socially distant times'},
            {id: 2, text: 'Live streaming allows you to build rapport and strengthen relationships with potential buyers '},
            {id: 3, text: 'Reduce travel times to viewings providing significant travel and time savings'}
        ],
        imgAvailableOnMobile: true
    },
    {
        title: 'Market Share',
        tagline: 'Get a deeper understanding of the local markets you operate in',
        state: 'market-share',
        tab: '/marketinganddatasolutions',
        description: 'Make more informed decisions around your business growth strategy.  Keep up to speed with key metrics, stock, selling prices, time to sell and time to sell. ',
        benefits: [
            {id: 1, text: 'Real-time and easy understand market share data'},
            {id: 2, text: 'Get an overview and drill down into areas and local markets'},
            {id: 3, text: 'Includes key metrics & bespoke insights allowing you to see how your business is performing in each locality'}
        ],
        imgAvailableOnMobile: false
    },
    {
        title: 'Listing Performance',
        tagline: 'Quickly assess how your business is trading over time',
        state: 'listing-performance',
        tab: '/marketinganddatasolutions',
        description: 'You know your business and now with our insight dashboards, you can get a richer understanding of the market it operates in. This tool enables you to compare your new listings year on year by locality and property type.',
        benefits: [
            {id: 1, text: 'Understand areas of relative over and under performance'},
            {id: 2, text: 'Track trends year on year and set your strategies accordingly'},
            {id: 3, text: 'Ability to track by location, property type and number of bedrooms'}
        ],
        imgAvailableOnMobile: false
    },
    {
        title: 'Top Spot Ad',
        tagline: 'Be #1 property listing in your area',
        state: 'top-spot-ad',
        tab: '/advertisingoptions',
        description: 'Ensure your listings are seen at a locality or county level with a Top Spot ad. This ensures when a user searches in an area you are guaranteed to be the number one listing they see. Top Spot ad attracts up to four times more views than a standard advert.',
        benefits: [
            {id: 1, text: 'Up to 4 times more ad views'},
            {id: 2, text: 'Number one listing in your area'},
            {id: 3, text: 'Featured ad badge'},
            {id: 4, text: 'Enhanced display'},
            {id: 5, text: 'Be seen as Market Leader in your area'},
            {id: 6, text: 'Highlighted badge on listings to attract attention'}
        ],
        imgAvailableOnMobile: true
    },
    {
        title: 'Premium Ad',
        tagline: 'Twice as many ad views and guaranteed to be on first page of your local search',
        state: 'premium-ad',
        tab: '/advertisingoptions',
        description: 'A Premium Ad guarantees your property to be on the first page of your locality for 28 days. The average Premium Ad attracts up to double the views as a standard advert. It also gives you an interactive photo gallery within the search page and a direct link to the estate agent from the search page.',
        benefits: [
            {id: 1, text: 'Up to double the amount of ad views'},
            {id: 2, text: 'Guaranteed to be on page one in your locality'},
            {id: 3, text: 'Premium Ad badge'},
            {id: 4, text: 'Interactive photo gallery in search'},
            {id: 5, text: 'Highlighted premium badge on listing to attract attention'}
        ],
        imgAvailableOnMobile: true
    },
    {
        title: 'Local Featured Agent',
        tagline: 'Target prospective vendors in key areas to win new instructions',
        state: 'local-featured-agent',
        tab: '/advertisingoptions',
        description: 'This is an exclusive ad position, displaying within the search results pages. It appears on every page of the search results within each locality. Local areas banners can hold a strong message relevant to the locality. Large format desktop banners, mobile MPUs and app banners are all available targeted to your locality.',
        benefits: [
            {id: 1, text: 'Win more instructions'},
            {id: 2, text: 'Give your business stand out branding'},
            {id: 3, text: 'Drive traffic to your own website'},
            {id: 4, text: 'Industry leading clickthroughs'},
            {id: 5, text: 'Flexibility of design'},
            {id: 6, text: 'Be seen as a Market Leader'}
        ],
        imgAvailableOnMobile: true
    },
    {
        title: 'Homepage Takeover',
        tagline: 'Own the gateway to MyHome.ie for a day',
        state: 'homepage-takeover',
        tab: '/advertisingoptions',
        description: 'A homepage takeover allows you to place your advert around the traditional MyHome.ie front page on desktop. Only your advert will appear on the homepage’s mobile display that day as well. With the ability to achieve approximately 100,000 impressions per day, this is the perfect way to let people know about a new launch.',
        benefits: [
            {id: 1, text: 'Immediate visual impact for your message'},
            {id: 2, text: 'Full branding and flexibility of design and message'},
            {id: 3, text: 'Be seen as Market Leader'},
            {id: 4, text: 'Exclusive advert on mobile'},
            {id: 5, text: 'Prominent agency branding'}
        ],
        imgAvailableOnMobile: true
    },
    {
        title: 'Homepage Featured Property',
        tagline: 'Achieve up to 8 x more ad views',
        state: 'homepage-featured-property',
        tab: '/advertisingoptions',
        description: 'A homepage featured property sits on the MyHome.ie homepage and is on rotation with a maximum of seven properties for one week. Running from Thursday through to Wednesday, this allows you to highlight key properties and for users to be able to bypass the search and go directly to them in one click. A homepage featured property attracts approximately eight times more views than a standard advert.',
        benefits: [
            {id: 1, text: '8 times more ad views'},
            {id: 2, text: 'Prominence on MyHome.ie homepage'},
            {id: 3, text: 'Directly click through to property without searching'},
            {id: 4, text: 'Available on desktop or mobile'},
            {id: 5, text: 'Be seen as Market Leader with a prominent Homepage position'}
        ],
        imgAvailableOnMobile: true
    },
    {
        title: 'Property of the Day',
        tagline: 'Prime listing spot on the MyHome.ie home page',
        state: 'property-of-the-day',
        tab: '/advertisingoptions',
        description: 'This is an exclusive position on the MyHome.ie home page for 24 hours ensuring your property or development receives maximum exposure. With an ad three times larger than normal and a prominent estate agent logo, this is the ideal way to get your ad noticed by thousands of visitors each day',
        benefits: [
            {id: 1, text: '3x larger ad unit'},
            {id: 2, text: 'Prominent agent branding on the MyHome.ie home page'},
            {id: 3, text: 'Primary listing on the home page'},
            {id: 4, text: 'Promote key property features.'},
            {id: 5, text: 'Desktop only product.'}
        ],
        imgAvailableOnMobile: false
    },
    {
        title: 'Targeted Display Ad',
        tagline: 'Reach your desired audience efficiently',
        state: 'targeted-display-advertising',
        tab: '/advertisingoptions',
        description: 'Target your desired audience on MyHome.ie by locality, price or audience type. A targeted display advertising campaign that makes your brand stand out.',
        benefits: [
            {id: 1, text: 'You determine who to target'},
            {id: 2, text: 'Drive traffic to your website'},
            {id: 3, text: 'Highlight your brand or development'},
            {id: 4, text: 'Receive industry leading clicks'},
            {id: 5, text: 'Highly targeted based on your needs'}
        ],
        imgAvailableOnMobile: true
    },
    {
        title: 'Social',
        tagline: 'Use MyHome.ie’s social channels to target a wider audience',
        state: 'social',
        tab: '/advertisingoptions',
        description: 'Showcase your properties or developments to Ireland\'s most engaged property audience. MyHome’s social channels have 4x more engagement than our nearest competitor.',
        benefits: [
            {id: 1, text: 'Highlight key events such as open viewings'},
            {id: 2, text: 'Boost your presence across various social channels'},
            {id: 3, text: 'Target by age range, interests and other key criteria'},
            {id: 4, text: 'Drive more enquiries and leads'},
            {id: 5, text: 'Link back to your listing on MyHome'}
        ],
        imgAvailableOnMobile: true
    },
    {
        title: 'CRM',
        tagline: 'Manage your full property lifecycle',
        state: 'crm',
        tab: '/agentservices',
        description: "MyHome.ie's CRM allows you to manage the full lifecycle of your property online - anytime, anywhere. The platform guides you through all stages of the sales lifecycle capturing data, generating documents and managing appointments automatically. The Property Manager CRM aso manages appraisals, valuations, instructions, compliance documents, sale agreed listings, offers, solicitors, sold properties, invoice generation and closed listings.",
        benefits: [
            {id: 1, text: 'Ability to manage the full lifecycle of a property'},
            {id: 2, text: 'Access property details any time, any where'},
            {id: 3, text: 'Automatically have viewings added to your calendar'},
            {id: 4, text: 'Update property details instantly'}
        ],
        imgAvailableOnMobile: true
    },
    {
        title: 'Estate Agent Websites',
        tagline: 'Enhance your brand with your own website',
        state: 'estate-agent-website',
        tab: '/agentservices',
        description: 'MyHome.ie have specialised in agent websites for over the last 10 years., currently hosting sites for over 100 agencies. We offer customised mobile responsive websites which allow you to make a single upload to both MyHome.ie and your own site. You can choose from either templated options or a custom design, receiving hosting and support from MyHome.ie 24 hours a day, 365 days of the year.',
        benefits: [
            {id: 1, text: 'Boost your brand and SEO with your own website'},
            {id: 2, text: 'Generate more leads'},
            {id: 3, text: 'Single upload to both MyHome.ie and your own site'},
            {id: 4, text: 'Mobile responsive'},
            {id: 5, text: 'Hosting and support from MyHome.ie 24/7 365 days a year.'},
            {id: 6, text: 'Have a place to link all of your  advertising campaigns back to'}
        ],
        imgAvailableOnMobile: true
    },
    {
        title: 'Local Partnerships',
        tagline: 'Avail of MyHome.ie partners to boost your brand',
        state: 'local-partnerships',
        tab: '/groupsolutions',
        description: 'As part of The Irish Times group, MyHome.ie has a link up with a wide range of regional media titles in Cork, Mayo, Waterford, Carlow, Roscommon, Kildare and Laois. We have teamed up with local titles here to bring a Local featured agent banner appearing on their website. This ensures you are not only reaching your audience on MyHome.ie but on your local news website as well.',
        benefits: [
            {id: 1, text: 'Reach a wider audience in your locality'},
            {id: 2, text: 'An extra selling point to attract vendors'},
            {id: 3, text: 'Additional traffic to your brochures on MyHome.ie'},
            {id: 4, text: 'Prominent agency branding'}
        ],
        imgAvailableOnMobile: true
    }
];
