<div class="Tabs row justify-content-center">
    <a [href]="currentUrl == tab.url ? '/' : tab.url" *ngFor="let tab of tabs" class="Tabs__Tab col-md-3 h-100" 
    [ngClass]="tab.url == currentUrl ? 'Tabs__Tab--selected' : ''">
 
      <div class="row justify-content-center">
        <div class="col-md-6 col-sm-12 mb-2">
          <img class="w-75 d-block m-auto" [src]="tab.url == currentUrl ? tab.selectedImgSrc : tab.imgSrc" />
        </div>
      </div>
      <div class="row justify-content-sm-center">
        <div class="col-sm-12">
          <span><p class="text-center mb-0 pb-0">{{tab.title}}</p></span>
        </div>
     </div>
    </a>
</div>
