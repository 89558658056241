import { Component, OnInit, ViewEncapsulation,  Input, ChangeDetectorRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { TABS } from 'src/app/models/tabs';


@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SidenavComponent implements OnInit {

  @Input() sidenav: MatSidenav;  
  links = TABS;

  constructor() { }

  ngOnInit() {}


}
