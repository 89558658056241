<mat-toolbar class="MobileNavigation">
  <a class="MobileNavigation__LogoContainer" routerLink="/">
    <img
      class="MobileNavigation__Logo"
      src="https://myhomesan.azureedge.net/media/myhome-cdn/brand-main-logo.jpg"
      alt="My Home Logo"
    />
    <span> Estate Agent Solutions</span>
  </a>
  <div class="MobileNavigation__ToolbarsContainer">
    <div class="ml-3" (click)="sidenav.toggle()">
      <app-mh-icon
        class="MobileNavigation__ToolbarsIcon"
        icon="bars"
      ></app-mh-icon>
    </div>
  </div>
</mat-toolbar>
