import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { ProductComponent } from './pages/product/product.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

const routes: Routes = [
  { path: '', component: HomepageComponent},
  { path: 'marketinganddatasolutions', component: HomepageComponent},
  { path: 'advertisingoptions', component: HomepageComponent},
  { path: 'agentservices', component: HomepageComponent},
  { path: 'groupsolutions', component: HomepageComponent},
  { path: 'market-research', component: ProductComponent},
  { path: 'valuations-tool', component: ProductComponent},
  { path: 'myhome-live', component: ProductComponent},
  { path: 'market-share', component: ProductComponent},
  { path: 'listing-performance', component: ProductComponent},
  { path: 'top-spot-ad', component: ProductComponent},
  { path: 'premium-ad', component: ProductComponent},
  { path: 'local-featured-agent', component: ProductComponent},
  { path: 'homepage-takeover', component: ProductComponent},
  { path: 'homepage-featured-property', component: ProductComponent},
  { path: 'property-of-the-day', component: ProductComponent},
  { path: 'targeted-display-advertising', component: ProductComponent},
  { path: 'social', component: ProductComponent},
  { path: 'crm', component: ProductComponent},
  { path: 'estate-agent-website', component: ProductComponent},
  { path: 'local-partnerships', component: ProductComponent},
  { path: 'contact-us', component: ContactUsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
