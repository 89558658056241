<div class="Navigation">
  <div class="container">
    <nav>
      <a class="Navigation__LogoContainer" routerLink="/">
        <img
          class="Navigation__Logo"
          src="https://myhomesan.azureedge.net/media/myhome-cdn/brand-main-logo.jpg"
          alt="My Home Logo"
        />
        <span> Estate Agent Solutions</span>
      </a>
    </nav>
  </div>
</div>
