<div class="Footer">
    <div class="Footer__Container container">
        <span>© Copyright MyHome.ie {{CurrentYear}}</span>
        <ul class="Footer__SiteLinks ">
            <li><a class="Footer__Link" href="https://www.myhome.ie/terms" target="_blank">Terms & Conditions</a></li>
            <li><a class="Footer__Link" href="https://www.myhome.ie/cookiepolicy" target="_blank">Cookie Policy</a></li>
            <li><a class="Footer__Link" href="https://www.myhome.ie/privacy" target="_blank">Privacy Policy</a></li>
            <li><a class="Footer__Link" href="/contact-us">Contact Us</a></li>
        </ul>
    </div>
</div>
