import { Component, OnInit } from '@angular/core';
import { PRODUCTS } from '../../../app/models/products';
import { Router } from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  products = PRODUCTS;
  heading: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.triggerAnimations();

    if(this.router.url.length > 1) {
      this.products = this.products.filter((product) => product.tab == this.router.url);
      this.heading = "Recommended products";
    } else {
      this.heading = "All products";
    }
  }

  triggerAnimations() {
    window.scrollTo(0, 1);
    window.scrollTo(0, 0);
  }

}
