import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TABS } from '../../../app/models/tabs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  tabs = TABS;
  currentUrl;

  constructor(private router: Router) { }

  ngOnInit() {
    this.currentUrl = this.router.url;
  }

}
