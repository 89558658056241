 <div class="Homepage">
  <app-banner state="/"></app-banner>

  <div class="container mb-5">
    <div class="row Homepage__Heading justify-content-center">
      <h1 class="text-center">{{heading}}</h1>
      <div class="Homepage__Heading--border"></div>
    </div>

    <div class="row justify-content-center">
      <div *ngFor="let product of products" class="col-12 col-md-4 col-sm-6 mb-4 d-flex align-items-stretch">
        <a href="{{product.state}}" class="card w-100" wmAnimate="fadeInUp" aos once>
          <img class="card-img-top" src="../../assets/products/{{product.state}}.png" alt="Card image cap">
          <div class="card-body">
            <h5 class="card-title">{{product.title}}</h5>
            <p class="card-text">{{product.tagline}}</p>
          </div>
        </a>
      </div>

    </div>
  </div>

  <app-strong-numbers></app-strong-numbers>
  <app-team></app-team>
  <app-testimonials></app-testimonials>
</div>