import { Component, OnInit } from '@angular/core';
import { faClock } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  faClock = faClock;
  
  constructor() { }

  ngOnInit(): void {
    this.triggerAnimations();
  }

  triggerAnimations() {
    window.scrollTo(0, 1);
    window.scrollTo(0, 0);
  }

}
