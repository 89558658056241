export const TABS = [{
        title: 'Marketing & Data Solutions',
        url: '/marketinganddatasolutions',
        imgSrc: '../../assets/marketing.png',
        selectedImgSrc: '../../assets/marketing-blue.png'
    },
    {
        title: 'Advertising Options',
        url: '/advertisingoptions',
        imgSrc: '../../assets/advertising.png',
        selectedImgSrc: '../../assets/advertising-blue.png'
    },
    {
        title: 'Agent Services',
        url: '/agentservices',
        imgSrc: '../../assets/agent.png',
        selectedImgSrc: '../../assets/agent-blue.png'
    },
    {
        title: 'Group Solutions',
        url: '/groupsolutions',
        imgSrc: '../../assets/group.png',
        selectedImgSrc: '../../assets/group-blue.png'
    }
];
