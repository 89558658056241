import { Component, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { PlatformCheckerService } from './services/platformChecker.service';
import { DOCUMENT } from "@angular/common";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isMobile: boolean;
  screenSizeChangeToMobileSubscription: Subscription;

  constructor(private _platformCheck: PlatformCheckerService, @Inject(DOCUMENT) private document: Document) { 
    this.isMobile = this._platformCheck.isMobile();
    this.screenSizeChangeToMobileSubscription = this._platformCheck.screenSizeChangeToMobile.subscribe(val => this.isMobile = val);
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.screenSizeChangeToMobileSubscription) {
      this.screenSizeChangeToMobileSubscription.unsubscribe();
    }
  }

  openedStart() {
    this.document.body.style.overflow = "hidden";
  }

  closedStart() {
    this.document.body.style.overflow = "auto";
  }
  
}
