import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DesktopNavigationComponent } from './shared/desktop-navigation/desktop-navigation.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { PlatformCheckerService } from './services/platformChecker.service';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MhIconModule } from './shared/mh-icons/mh-icon.module';
import { SidenavComponent } from './shared/mobile-sidenav/sidenav.component';
import { MobileNavigationComponent } from './shared/mobile-navigation/mobile-navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BannerComponent } from './shared/banner/banner.component';
import { TabsComponent } from './shared/tabs/tabs.component';
import { FooterComponent } from './shared/footer/footer.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ProductComponent } from './pages/product/product.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { StrongNumbersComponent } from './shared/strong-numbers/strong-numbers.component';
import { TestimonialsComponent } from './shared/testimonials/testimonials.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { AnimateComponent } from './animate/animate.component';
import { TeamComponent } from './shared/team/team.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {};

@NgModule({
  declarations: [
    AppComponent,
    DesktopNavigationComponent,
    HomepageComponent,
    SidenavComponent,
    MobileNavigationComponent,
    BannerComponent,
    TabsComponent,
    FooterComponent,
    ProductComponent,
    StrongNumbersComponent,
    TestimonialsComponent,
    ContactUsComponent,
    AnimateComponent,
    TeamComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatSidenavModule, 
    MatToolbarModule, 
    MatListModule,
    MhIconModule,
    BrowserAnimationsModule,
    NgbModule,
    FontAwesomeModule,
    SwiperModule
  ],
  providers: [
    PlatformCheckerService,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
