<div class="Testimonials">
    <div class="Testimonials__Panel">
        <div class="container">
            <h2 class="Testimonials__Heading">Testimonials</h2>
            <div class="Testimonials__Heading--border"></div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-12 mb-4"> 
                    <div class="Testimonials__Testimonial" wmAnimate="fadeInRight" aos once> 
                        <fa-icon [icon]="faQuoteLeft" class="Testimonials__Icons" size="3x"></fa-icon>
                        <p class="font-italic mt-3">We’re delighted with the website. It’s easy to update and showcases our range of properties well across all platforms.It was easy to work with our account manager Vincent to bring the site to fruition and clients have been very complimentary about it since it launched. 
                            MyHome.ie account manager Vincent Brady explained the benefits of working with MyHome.ie on an agent website.
                        </p>
                        <p class="Testimonials__Text--blue my-4">- Fiona Salter, McPeake Auctioneers</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 mb-4"> 
                    <div class="Testimonials__Testimonial" wmAnimate="fadeInUp" aos once> 
                        <fa-icon [icon]="faQuoteLeft" class="Testimonials__Icons" size="3x"></fa-icon>
                        <p class="font-italic mt-3">Deirdre Barry from MyHome has been a pleasure to deal with, she brings a personal touch to the online property platform for estate agents. She is always on hand for expert guidance, assistance with social media campaigns and has extensive knowledge of the market and how 
                            to target our audience. She is a dedicated account manager, always making time for her clients and always at the other end of a phone should we have any issues. Sherry FitzGerald Limerick would have no hesitation in recommending Deirdre and the team at MyHome for their outstanding service and professionalism.</p>
                        <p class="Testimonials__Text--blue my-4">- Sinead McMullen, Sherry Fitzerald Limerick </p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 mb-4"> 
                    <div class="Testimonials__Testimonial" wmAnimate="fadeInLeft" aos once> 
                        <fa-icon [icon]="faQuoteLeft" class="Testimonials__Icons" size="3x"></fa-icon>
                        <p class="font-italic mt-3">We are delighted with our new web design. We found the process very easy and our Account Manager Michael was excellent to deal with, tailoring our ideas and producing a fabulous user friendly website for our customers. 
                            We would highly recommend and have received very positive feedback since going live with the new website.</p>
                        <p class="Testimonials__Text--blue my-4">- Marie Byrne, Thomas M. Byrne & Son Ltd.</p>
                    </div>
                </div>
            </div>             
        </div>
    </div>
</div>