<div class="ContactUs">
    <app-banner state="/contact-us"></app-banner>

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-sm-10 col-md-8">
                <div class="ContactUs__Container">
                    <h1 class="text-center">Contact Us</h1>
                    <p class="text-center font-italic mt-3 font-weight-bold ContactUs__TextBlue"> We'd love to hear from you.</p>

                    <div class="row mt-4">
                        <div class="col-12 col-md-7"> 
                            <h4>Advertising enquiries:</h4>
                            <p><b><a href="mailto:advertising@myhome.ie">advertising@myhome.ie</a></b></p>
                            <h4>Rental enquiries:</h4>
                            <p><b><a href="mailto:rentals@myhome.ie">rentals@myhome.ie</a></b></p>
                        </div>
                        <div class="col-12 col-md-5 text-center d-flex flex-column justify-content-center">
                            <fa-icon [icon]="faClock" size="2x"></fa-icon>
                            <p>Our office hours are between 9am and 5.30pm Monday - Friday</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
