<div class="Product">
    <app-banner [state]="activeProduct[0].state"></app-banner>
    <div class="Product__Container container">
        <div class="row justify-content-center">
            <h1 class="text-center">{{activeProduct[0].title}}</h1>
            <div class="Product__Heading--border"></div>
        </div>

        <div class="row justify-content-sm-center">
            <div class="col-12">
                <p class="Product__Tagline text-center">{{activeProduct[0].tagline}}</p>
            </div>
        </div>

        <div class="row justify-content-sm-center">
            <div class="col-12">
                <p class="text-center">{{activeProduct[0].description}}</p>
            </div>
        </div>

        <div class="row mt-4">
            <div class="col-md-5 col-sm-12 Product__Benefits">
                <p class="font-weight-bold" wmAnimate="fadeInUp" aos once>Benefits:</p>
                <div class="d-flex" *ngFor="let benefit of benefitsBesideDesktop" wmAnimate="fadeInUp" aos once>
                    <fa-icon [icon]="faHeart" class="mr-1 Product__Icons--blue"></fa-icon><p>{{benefit.text}}</p>
                </div>
                <div class="row justify-content-sm-center mt-2">
                    <div class="col-md-8 col-12" wmAnimate="fadeInUp" aos once>
                        <a class="btn w-100 mb-4" href="/contact-us" >Contact Us</a>
                    </div>
                </div>
            </div>
            <div class="col-md-7 col-sm-12">
                <img class="mw-100 d-block m-auto" src="../../../assets/desktop/{{activeProduct[0].state}}.png" wmAnimate="fadeInLeft" aos once/>
            </div>
        </div>

        <div *ngIf="isImgAvailableOnMobile" class="Product__Mobile row">
            <div class="col-md-7 col-sm-12">
                <img class="mw-100 d-block m-auto" src="../../../assets/mobile/{{activeProduct[0].state}}.png" wmAnimate="fadeInRight" aos once/>
            </div>
            <div class="col-md-5 col-sm-12 Product__Benefits">
                <p class="font-weight-bold" wmAnimate="fadeInUp" aos once>Benefits:</p>
                <div class="d-flex" *ngFor="let benefit of benefitsBesideMobile" wmAnimate="fadeInUp" aos once>
                    <fa-icon [icon]="faHeart" class="mr-1 Product__Icons--blue"></fa-icon><p>{{benefit.text}}</p>
                </div>
                <div class="row justify-content-sm-center mt-2">
                    <div class="col-md-8 col-12" wmAnimate="fadeInUp" aos once>
                        <a class="btn w-100" href="/contact-us">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-strong-numbers></app-strong-numbers>
    <app-team></app-team>
    <app-testimonials></app-testimonials>
 
</div>