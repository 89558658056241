import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MhIconComponent } from './mh-icon.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [MhIconComponent],
  exports: [MhIconComponent]
})
export class MhIconModule { }
