<div class="StrongNumbers">
    <div class="StrongNumbers__Panel">
        <div class="container">
            <h2 class="StrongNumbers__Heading">MyHome.ie Key Stats</h2>
            <div class="StrongNumbers__Heading--border"></div>
            <div class="row justify-content-sm-center text-white" wmAnimate="pulse" aos once>
                <div class="col-md-3 col-sm-6">
                    <fa-icon class="fa-2x" [icon]="faUsers"></fa-icon>
                    <h2 class="StrongNumbers__LargeText">1K+</h2>
                    <p>ESTATE AGENTS</p>
                </div>
                <div class="col-md-3 col-sm-6">
                    <fa-icon class="fa-2x" [icon]="faEye"></fa-icon>
                    <h2 class="StrongNumbers__LargeText">23M+</h2>
                    <p>VIEWS PER MONTH</p>
                </div>
                <div class="col-md-3 col-sm-6">
                    <fa-icon class="fa-2x" [icon]="faSmile"></fa-icon>
                    <h2 class="StrongNumbers__LargeText">1.1M+</h2>
                    <p>HAPPY USERS</p>
                </div>
                <div class="col-md-3 col-sm-6">
                    <fa-icon class="fa-2x" [icon]="faHome"></fa-icon>
                    <h2 class="StrongNumbers__LargeText">10K+</h2>
                    <p>PROPERTIES</p>
                </div>
            </div>
        </div>
    </div>
</div>