import { Component, OnInit, Input } from '@angular/core';
import { PlatformCheckerService } from 'src/app/services/platformChecker.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  isMobile: boolean;
  screenSizeChangeToMobileSubscription: Subscription;
  @Input ('state') state;
  isHome: boolean;
  isContactPage: boolean;

  constructor(private _platformCheck: PlatformCheckerService) { 
    this.isMobile = this._platformCheck.isMobile();
    this.screenSizeChangeToMobileSubscription = this._platformCheck.screenSizeChangeToMobile.subscribe(val => this.isMobile = val);
  }

  ngOnInit() {
    this.isHome = this.state == "/";
    this.isContactPage = this.state == "/contact-us"
  }

  ngOnDestroy() {
    if (this.screenSizeChangeToMobileSubscription) {
      this.screenSizeChangeToMobileSubscription.unsubscribe();
    }
  }
}
