import { Component, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { TEAM } from 'src/app/models/team';
import { PlatformCheckerService } from 'src/app/services/platformChecker.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  public config: SwiperConfigInterface = {};

  team = TEAM;
  isMobile: boolean;
  screenSizeChangeToMobileSubscription: Subscription;

  constructor(private _platformCheck: PlatformCheckerService) { 
    this.isMobile = this._platformCheck.isMobile();
    this.screenSizeChangeToMobileSubscription = this._platformCheck.screenSizeChangeToMobile.subscribe(val => this.isMobile = val);
  }

  ngOnInit(): void {
    this.config = {
      direction: 'horizontal',
      slidesPerView: this.isMobile ? 1 : 3,
      keyboard: true,
      scrollbar: true,
      navigation: true,
      spaceBetween: 5,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
    };
  }

  ngOnDestroy() {
    if (this.screenSizeChangeToMobileSubscription) {
      this.screenSizeChangeToMobileSubscription.unsubscribe();
    }
  }

}
