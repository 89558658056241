import { Component, OnInit, ViewEncapsulation, Input, OnChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-mh-icon',
  templateUrl: './mh-icon.component.html',
  styleUrls: ['./mh-icon.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MhIconComponent implements OnInit {

  @Input() icon;
  constructor() { }

  ngOnInit() {
  }




  get absUrl() {
    let url = window.location.href;
    if (url.indexOf('#') > -1) {
      url = url.split('#')[0];
    }
    return url;
  }

}
