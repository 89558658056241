<div class="Banner">
   <div class="container">
     <div class="row justify-content-center mb-4" wmAnimate="landing" aos once>
      <h2 class="mb-md-4">Grow your business with MyHome.ie</h2>
     </div>
     
     <app-tabs *ngIf="!isMobile && isHome"></app-tabs>

     <div class="row justify-content-center">
      <div class="col-md-6 col-sm-12">
        <img class="mw-100 d-block mr-auto ml-auto Banner__ProductImg" *ngIf="!isHome && !isContactPage" src="../../assets/banner/{{state}}.png" wmAnimate="landing"  aos once>

        <img class="w-50 d-block mr-auto ml-auto Banner__ContactImg" *ngIf="!isHome && isContactPage" src="../../assets/contact-us.png" wmAnimate="landing" aos once>
       </div>
     </div>

   </div>

 </div>
