
import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';

import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss']
})
export class MobileNavigationComponent implements OnInit {

  @Input() sidenav: MatSidenav;


  constructor(

  ) {
  }

  ngOnInit() {

  }


}
