export const TEAM = [
  {
    name: "Joanne Geary",
    img: "../../assets/team/JoanneG.jpeg",
    jobTitle: "Managing Director",
  },
  {
    name: "John Callan",
    img: "../../assets/team/johnCallan.jpg",
    jobTitle: "Head of Product & Client Solutions",
  },
  {
    name: "Siobhain Bunni",
    img: "../../assets/team/SiobhanB.jpeg",
    jobTitle: "Head of Marketing & Sales",
  },
  {
    name: "Alan McGrane",
    img: "../../assets/team/alanMcGrane.jpg",
    jobTitle: "Chief Technology Officer",
  },
  {
    name: "Ryan Coyle",
    img: "../../assets/team/ryanCoyle.jpg",
    jobTitle: "Digital Partnerships Manager",
  },
  {
    name: "Deirdre Gleeson",
    img: "../../assets/team/deirdreGleeson.jpg",
    jobTitle: "Sales Manager",
  },
  {
    name: "Siobhan Devaney",
    img: "../../assets/team/siobhanDevany.jpg",
    jobTitle: "Product Marketing Lead",
  },
  {
    name: "Deirdre Barry",
    img: "../../assets/team/deirdreBarry.jpg",
    jobTitle: "Senior Account Manager",
  },
  {
    name: "Michael Ryan",
    img: "../../assets/team/michaelRyan.jpg",
    jobTitle: "Senior Account Manager",
  },
  {
    name: "Niamh Briody",
    img: "../../assets/team/Niamh Briody.png",
    jobTitle: "Account Manager",
  },
  {
    name: "Ciaran Hand",
    img: "../../assets/team/CiaranH.jpeg",
    jobTitle: "Account Manager",
  },
];
