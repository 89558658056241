<div *ngIf="!isMobile">
  <header>
    <app-desktop-navigation></app-desktop-navigation>
  </header>
  <router-outlet></router-outlet>
  <footer>
    <app-footer></app-footer>
  </footer>
</div>

 <div *ngIf="isMobile">
  <mat-sidenav-container> 
    <mat-sidenav #sidenav mode="over" position="end" (openedStart)="openedStart()" (closedStart)="closedStart()">
        <app-sidenav [sidenav]="sidenav"></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
      <header>
        <app-mobile-navigation [sidenav]="sidenav"></app-mobile-navigation>
      </header>
      <router-outlet></router-outlet>
      <footer>
        <app-footer></app-footer>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div> 